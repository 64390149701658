<template>
  <div>
    <div class="menuList">
      <div
        v-for="item in marketList"
        @click="changeArea(item)"
        :class="['menu_i', myId == item.id ? 'actMenu' : '']"
        :key="item.id"
        v-show="item.isShow"
      >
        {{ item.text }}
      </div>
    </div>
    <div class="xian"></div>
  </div>
</template>

<script>
import * as api from "@/api/goods";

export default {
  name: "",
  props: {
    myId: {

    },
  },
  data() {
    return {
      marketList: [
        {
            text: this.$t('MN_F_49'),
          id: 0,
          path: "/marketDynamics",
          name: 'marketDynamics',
        },
        {
            text: this.$t('ENU_MENU_520'),
          id: 7,
          path: "/marketDynamics",
          name: 'activeZone',
        },
        {
            text: this.$t('ENU_MENU_530'),
          id: 1,
          path: "/marketDynamics",
          name: 'incomeDetail',
        },
        {
            text: this.$t('ENU_MENU_540'),
          id: 3,
          path: "/marketDynamics",
            name: 'bonusSource',
        },
        // {
        //   text: "云代奖金",
        //   id: 3,
        //   path: "/generation",
        // },
        {
            text: this.$t('ENU_MENU_560'),
          id: 4,
          path: "/marketDynamics",
            name: 'appraisal',
        },
        {
            text: this.$t('ENU_MENU_570'),
          id: 5,
          path: "/marketDynamics",
            name: 'registration',
        },
        {
            text: this.$t('ENU_MENU_580'),
          id: 6,
            path: "/marketDynamics",
            name: 'investment',
        },
      ],
    };
  },
    created() {
        this.getMenuList()
    },
    methods: {
      getMenuList() {
          api.menuList().then((res) => {
              res.data.forEach((item) => {
                  this.marketList.forEach((ctem) => {
                      if (ctem.name == item.menuKey) {
                          ctem.isShow = true
                      }
                  })
              })
          })
      },
    changeArea(item) {
      this.specialArea = item.id;
      this.myId = item.id
      //   this.research()
      this.$router.push({
        path: item.path,
        query: { id: item.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.menuList {
  display: flex;
  margin-top: 20px;
  padding: 0 24px;
  box-sizing: border-box;
  .menu_i {
    margin-right: 70px;
    line-height: 50px;
    cursor: pointer;
  }
  .actMenu {
    border-bottom: 5px solid #b42b2a;
  }
}
.xian {
  height: 5px;
  background: rgba(0, 0, 0, 0.05);
  margin-top: -5px;
}

.goodArea {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  width: 100%;
  height: 80px;
  border-bottom: 5px solid rgba(0, 0, 0, 0.05);
  .goodArea_i {
    margin: 0 35px;
    text-align: center;
    cursor: pointer;
  }
  .actArea {
    color: #b42b2a;
  }
}
</style>